.mainContainer {
    display: grid;
    grid-template-rows: 10% 90%;
    // =======================
    width: 100vw;
    height: 100vh;
}

.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.subContainer {
    display: grid;
    grid-template-columns: 11% 89%;
    height: 100%;
}

.infoContainer {
    display: grid;
    grid-template-rows: 10% 45% 45%;
}

.infoTitle {
    font-family: SFHeavy;
    font-size: 4vh;
    padding: 1%;
}

.chartContainerA {
    display: grid;
    grid-template-columns: 50% 50%;
}

.chartTitle {
    font-family: SFHeavy;
    color: gray;
}

.chartContainerB {
    position: relative;
}

.switchContainer {
    position: absolute;
    height: 20px;
    right: 30px;
    top: 30px;
    z-index: 99;
}

.switchLabels {
    display: inline-block;
    vertical-align: middle;
    font-family: AvenirHeavy;
    font-size: 10px;
    padding: 10px;
}
