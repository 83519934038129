.mainContainer {
    display: grid;
    grid-template-rows: 8.33% 91.67%;
    // =======================
    width: 100vw;
    height: 100vh;
}

.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.subContainer {
    display: grid;
    grid-template-columns: 15.625% 84.375%;
    height: 100%;
}

.infoContainer {
    position: relative;
    display: grid;
    grid-template-rows: 10% 90%;
}

.infoTitle {
    font-family: AvenirNextDemiBold;
    font-size: 4.17vh;
    padding: 0.5% 1%;
}

.infoSubtitle {
    font-size: 2vh;
    margin-left: 10px;
}

.ytdContainer {
    margin-top: -5px;
    font-family: AvenirNextDemiBold;
    font-size: 1.67vh;
    color: #939598;
}

.loadingContainer {
    height: 100vh;
    text-align: center;
}

.loading {
    vertical-align: middle;
}

.noDataContainer {
    position: absolute;
    width: 100%;
    top: 40%;
    text-align: center;
    font-family: AvenirHeavy;
    font-size: 4vh;
    z-index: 10;
}

.alcanceContainer {
    position: absolute;
    top: 2vh;
    right: 2vw;
    display: flex;
    flex-direction: column;
    text-align: right;
}
