:root {
    --calendarMainColor: #68476c;
    --calendarLightColor: #9d889f;
    --calendarSelectedRangeColor: #cccccc;
}
$calendarMainColor: var(--calendarMainColor);
$calendarLightColor: var(--calendarLightColor);
$calendarSelectedRangeColor: var(--calendarSelectedRangeColor);

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: $calendarLightColor;
}

.react-calendar__tile--hasActive {
    background: $calendarLightColor;
}

// ====================================

.date-selector {
    width: 100%;
    margin-bottom: 2px;
}

.date-selector div[class$="wrapper"] {
    width: 80%;
    background-color: rgb(255, 255, 255);
    border: none;
    margin: 0 auto;
    flex-grow: 0;
    text-align: center;
    font-size: 1.3vh;
    font-family: AvenirBook;
    color: #9a9ea6;
}

.date-selector div[class$="inputGroup"] {
    max-width: 50%;
    min-width: 45%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: inline-block;
    font-family: AvenirBook;
    color: #9a9ea6;
}

.inputGroup input {
    color: #9a9ea6;
}

.date-selector span[class$="range-divider"] {
    margin: 0px 0px;
    font-weight: bold;
}

.date-selector div[class$="--open"] {
    font-family: AvenirBook;
    width: 488px;
    left: 15vw !important;
    top: -1.5vh !important;
    font-size: 12px;
}

.date-selector div[class$="navigation"] * {
    text-transform: capitalize;
}

.date-selector div[class$="viewContainer"] * {
    font-size: 12px;
    text-transform: capitalize;
    text-align: center;
}

.react-calendar__tile--active {
    background-color: $calendarSelectedRangeColor;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    color: #fff;
    background-color: $calendarMainColor;
}

.react-calendar__tile--rangeStart {
    padding: 0 !important;
    background-color: $calendarMainColor;
    // border-radius: 50% 0 0 50%;
}

.react-calendar__tile--rangeStart abbr {
    display: inline-block;
    height: 24px;
    width: 24px;
    border-radius: 25px;
    line-height: 200%;
    color: white;
    // background-color: #000;
    font-weight: bolder;
}

.react-calendar__tile--rangeEnd {
    padding: 0 !important;
    background-color: $calendarMainColor;
    // border-radius: 0 50% 50% 0;
}

.react-calendar__tile--rangeEnd abbr {
    display: inline-block;
    height: 24px;
    width: 24px;
    line-height: 200%;
    border-radius: 25px;
    color: white;
    // background-color: #000;
    font-weight: bolder;
}

abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
}

.react-calendar__month-view__days__day--weekend {
    color: $calendarMainColor;
}

.react-calendar {
    border: none;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__month-view__weekdays__weekday abbr {
    display: block;
    visibility: hidden;
    padding-left: 4px;
    display: inline-block;
    width: 14px;
}
.react-calendar__month-view__weekdays__weekday abbr::first-letter {
    visibility: visible;
}
/* .react-calendar {
    height: 256px;
} */
.react-calendar__tile {
    padding: 4px 0px;
    display: inline-block;
}
.react-calendar button {
    margin: 3px 0;
    // height: 24px;
    width: 24px;
}
.react-calendar__month-view {
    margin: 17px !important;
}
.react-calendar__navigation {
    margin-bottom: 0;
    height: 30px;
}
.react-calendar__tile:disabled {
    background-color: #fff;
    color: rgba(16, 16, 16, 0.3) !important;
}
/* .react-calendar__tile--hasActive {
    background-color: rgb(204, 204, 204);
} */
.react-calendar__navigation__label__labelText {
    text-transform: lowercase !important;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    background-color: #A9A9A9;
}
