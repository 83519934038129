.filterContainer {
    height: 100%;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.fechaContainer {
    margin-top: 2vh;
    padding-bottom: 1.3vh;
    border-bottom: 1px solid #e8e8ec;
}

.sectionTitleA {
    font-family: AvenirHeavy;
    font-size: 1.43vh;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px 0.78vw;
}

.sectionIconA {
    height: 2.08vh;
    margin-right: 0.78vw;
    vertical-align: middle;
    filter: brightness(0) saturate(100%) invert(65%) sepia(1%) saturate(3316%) hue-rotate(179deg) brightness(89%)
        contrast(87%);
}

.sectionTextA {
    vertical-align: middle;
}

.flechaMenuPlegable {
    font-size: 1.5vh;
    color: #9a9ea6;
    float: right;
    vertical-align: middle;
}

.fechaOptionsContainer {
    display: grid;
    // grid-template-columns: 33% 33% 34%;
    margin-top: 1.17vh;
    margin-bottom: 1.25vh;
    text-align: center;
}

.optionSelected {
    font-family: AvenirMedium;
    font-size: 1.3vh;
    color: #363a43;
    padding-bottom: 1.3vh;
    border-bottom: 2px solid #363a43;
}

.optionUnselected {
    cursor: pointer;
    font-family: AvenirBook;
    font-size: 1.3vh;
    color: #8e8e8e;
    padding-bottom: 1.3vh;
    border-bottom: 1px solid #e9e9f0;
}

.mesesRangeContainer {
    font-family: AvenirBook;
    font-size: 1.3vh;
    margin-top: 1.17vh;
    margin-bottom: 0.5vh;
    text-align: center;
}

.mesesRangeDropdown {
    font-family: AvenirMedium;
    border: none;
    border-bottom: 1px solid #8e8e8e;
    margin: 0px 0.3vw;
}

.toggleContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
    margin-top: 1.56vh;
    padding-bottom: 1.6vh;
    border-bottom: 1px solid #e8e8ec;
}

.canalContainer {
    margin-top: 2.08vh;
    padding-bottom: 1.4vh;
    border-bottom: 1px solid #e8e8ec;
}

.radioContainer {
    margin-top: 0.91vh;
    padding-left: 2.125vw;
}

.menuOptionMainContainer {
    position: relative;
}

.menuOptionContainer {
    cursor: pointer;
    padding: 1.04vh 1.56vw;
}

.menuOptionContainer:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.sectionIconB {
    // height: 2.08vh;
    width: 1.2vw;
    margin-right: 0.58vw;
    vertical-align: middle;
    filter: brightness(0) saturate(100%) invert(34%) sepia(11%) saturate(545%) hue-rotate(185deg) brightness(89%)
        contrast(88%);
}

.sectionTextB {
    font-family: AvenirHeavy;
    font-size: 1.43vh;
    color: #9a9ea6;
    vertical-align: middle;
}

.sectionTextB_subtext {
    font-family: AvenirHeavy;
    font-size: 1vh;
    color: #9a9ea6;
}

.menuArrow {
    font-size: 2vh;
    color: #9a9ea6;
    float: right;
}

.menuInfoContainer {
    cursor: default;
    position: absolute;
    left: calc(100vw * 0.15625);
    bottom: 0;
    min-width: 350px;
    z-index: 99;
    background-color: white;
    // ==========================
    padding: 10px;
    // ==========================
    -webkit-box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
}

.menuInfoTitleContainer {
    display: grid;
    grid-template-columns: 30% 70%;
}

.menuInfoTitle {
    font-size: 24px;
    font-family: AvenirBlack;
}

/* .menuInfoTitleExtra {
    margin-bottom: 25px;
} */

.menuInfoTitleExtraA {
    display: inline-block;
    font-size: 24px;
    font-family: AvenirBlack;
}

.menuInfoTitleExtraB {
    display: inline-block;
    font-family: AvenirBook;
    font-size: 12px;
    margin-left: 5px;
}

.menuInfoCheckAllContainer {
    margin-bottom: -15px;
}

.twoColumnMenu {
    display: grid;
    grid-template-columns: 50% 50%;
}

.threeColumnMenu {
    display: grid;
    grid-template-columns: 33% 33% 34%;
    max-height: 30vh;
    overflow: scroll;
}

.capasContainer {
    margin-top: 2.08vh;
    padding-bottom: 3.91vh;
    border-bottom: 1px solid black;
}

.capasOption {
    margin: 0.84vh 0;
    padding: 0px 0.78vw;
}

.capasOptionTitle {
    display: inline-block;
    font-family: AvenirMedium;
    font-size: 1.3vh;
    color: #9a9ea6;
}

.switchContainer {
    float: right;
}

.reiniciarContainer {
    // margin-top: 1.04vh;
    padding-top: 1.2vh;
    // border-top: 1px solid black;
}

.reiniciarButton {
    cursor: pointer;
    display: block;
    margin: auto;
    width: 46.88%;
    padding: 0.26vh 0px;
    background-color: black;
    text-align: center;
    font-family: AvenirBlack;
    font-size: 1.3vh;
    color: white;
    border-radius: 2px;
}

// Estilos Radio Buttons ===========================================================================

.radioButtonContainer {
    cursor: pointer;
    display: block;
    position: relative;
    padding: 0.4vh 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Custom Radio Button */
/* Hide the browser's default radio button */
.radioButtonContainer input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.radioCheckmark {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 2.08vh;
    width: 2.08vh;
    background-color: white;
    border: 1px solid #363a43;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioButtonContainer:hover input ~ .radioCheckmark {
    background-color: rgba(54, 58, 67, 0.1);
}

/* When the radio button is checked, add a blue background */
.radioButtonContainer input:checked ~ .radioCheckmark {
    background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioButtonContainer input:checked ~ .radioCheckmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioButtonContainer .radioCheckmark:after {
    top: 15%;
    left: 18%;
    width: 1.4vh;
    height: 1.4vh;
    border-radius: 50%;
    background: #363a43;
}

.radioLabel {
    display: inline-block;
    margin-left: 0.78vw;
    vertical-align: middle;
    font-family: AvenirMedium;
    font-size: 1.05vh;
    color: #9a9ea6;
}

// Estilos Checkmarks ===========================================================================

.checkmarkContainer {
    display: block;
    position: relative;
    font-family: AvenirMedium;
    font-size: 1.15vh;
    color: #9a9ea6;
    padding-top: 0.52vh;
    padding-left: 2.4vw;
    margin: 2.2vh 0vh;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkmarkContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    // font-size: 12px;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    border: solid 1px rgba(54, 58, 67, 0.3);
    border-radius: 2px;

    left: 0;
    // font-size: 12px;

    height: 2.08vh;
    width: 2.08vh;
    background-color: rgb(255, 255, 255);
}

/* On mouse-over, add a grey background color */
.checkmarkContainer:hover input ~ .checkmark {
    background-color: #f0f0f0;
}

/* When the checkbox is checked, add a blue background */
.checkmarkContainer input:checked ~ .checkmark {
    background-color: white;
    border: solid 1px #363a43;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";

    background-image: "url(./icon/check@2x.png) no-repeat";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkmarkContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkmarkContainer .checkmark:after {
    left: 0.49vw;
    top: 0.26vh;
    width: 0.29vw;
    height: 0.78vh;
    border: solid #363a43;
    // font-size: 12px;

    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.disabledCheck {
    cursor: default;
    pointer-events: none;
    text-decoration: line-through;
    opacity: 0.4;
}

// Estilos Específicos del Switch ===========================================================================
.react-switch-bg {
    width: 200px !important;
}
