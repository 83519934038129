:root {
    --homeLogoFilter: brightness(0) invert(10%) sepia(15%) saturate(2458%) hue-rotate(245deg) brightness(92%)
        contrast(102%);
    --homeMainColor: #68476c;
    --homeImageBorder: 1px solid rgba(54, 58, 67, 0.4);
    --homeLogoWidth: 15%;
}
$homeLogoFilter: var(--homeLogoFilter);
$homeMainColor: var(--homeMainColor);
$homeImageBorder: var(--homeImageBorder);
$homeLogoWidth: var(--homeLogoWidth);

.mainContainer {
    display: grid;
    grid-template-rows: 8.33% 91.67%;
    // =======================
    width: 100vw;
    height: 100vh;
}

.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.fechaContainer {
    font-family: AvenirMedium;
    display: block;
    margin: 3% auto 2%;
    width: 90%;
    text-align: right;
    border-bottom: 1px solid #cdd1d4;
}

.panelLogo {
    display: block;
    margin: auto;
    width: $homeLogoWidth;
    padding: 1% 0px;
    filter: $homeLogoFilter;
}

.optionsMainContainer {
    margin-top: 7.5%;
}

/* .topRow {
    display: flex;
    justify-content: space-between;
    padding: 0 8vw;
} */

.topRow {
    display: flex;
    justify-content: space-between;
    padding: 0 12vw;
}

.bottomRow {
    display: flex;
    justify-content: space-between;
    padding: 0 12vw;
    margin-top: 2%;
}

.optionContainer {
    text-align: center;
}

.optionTitle {
    font-size: 2vh;
    font-family: AvenirHeavy;
    margin: 4% 0px;
}

.optionButton {
    cursor: pointer;
    display: block;
    margin: 4% auto 0px;
    width: 35%;
    font-size: 1.5vh;
    font-family: AvenirMedium;
    padding: 2% 0px;
    border: 1px solid $homeMainColor;
    border-radius: 7px;
}

.optionButton:hover {
    color: white;
    background-color: $homeMainColor;
}

.optionImageContainer {
    cursor: pointer;
    width: 100%;
    position: relative;
    border: $homeImageBorder;
}

/* .optionImageContainer:hover {
    width: 116%;
    right: 8%;
} */

.optionImage {
    height: 20.5vh;
}

.optionImageBorder {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10%;
    background-color: $homeMainColor;
}

.optionImageButton {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: 40%;
    text-align: center;
    color: white;
    font-weight: bold;
    padding: 10px 0px;
    border-radius: 5px;
    background-color: $homeMainColor;
}

.loadingContainer {
    height: 100vh;
    text-align: center;
}

.loading {
    vertical-align: middle;
}
