.mainContainer {
    position: relative;
    display: grid;
    grid-template-rows: 8.33% 91.67%;
    // =======================
    width: 100vw;
    height: 100vh;
}

.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.subContainer {
    display: grid;
    // grid-template-columns: 15% 85%;
    height: 100%;
}

.mapMainContainer {
    position: relative;
    height: 100%;
}

.mapContainer {
    height: 100%;
}

.closeContainer {
    cursor: pointer;
    position: absolute;
    background-color: black;
    color: white;
    padding: 2% 1%;
    font-size: 3vh;
    top: 2%;
    right: 0%;
}

.infoContainer {
    padding: 2.86vh 1.95vw;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.infoTitleMainContainer {
    display: grid;
    grid-template-columns: 9% 91%;
}

.infoTitleImgContainer {
    display: inline-block;
}

.infoTitleImg {
    width: 100%;
    filter: brightness(0) saturate(100%) invert(65%) sepia(1%) saturate(3316%) hue-rotate(179deg) brightness(89%)
        contrast(87%);
}

.infoTitleSubcontainer {
    display: inline-block;
    padding-left: 0.5vw;
}

.infoTitle {
    font-size: 2.6vh;
    font-family: AvenirHeavy;
    color: rgba(0, 0, 0, 0.54);
    word-wrap: break-word;
}

.infoSubtitle {
    margin-top: 0.65vh;
    font-size: 1.56vh;
    font-family: AvenirHeavy;
    text-transform: uppercase;
    color: #989898;
}

.infoNumberContainer {
    display: grid;
    grid-template-columns: 40% 60%;
    margin-top: 3.125vh;
    padding-bottom: 2.08vh;
    border-bottom: 1px solid #e8e8ec;
}

.infoNumberSubcontainer_A {
    border-right: 1px solid #e8e8ec;
}

.infoNumberSubcontainer_Title {
    font-family: AvenirMedium;
    font-size: 1.3vh;
    color: #9a9ea6;
}

.infoNumberSubcontainer_Img {
    height: 2.08vh;
    margin-right: 0.68vw;
    vertical-align: middle;
    filter: brightness(0) saturate(100%) invert(65%) sepia(1%) saturate(3316%) hue-rotate(179deg) brightness(89%)
        contrast(87%);
}

.infoNumberSubcontainer_B {
    padding-left: 1.56vw;
}

.infoNumberSubcontainer_Number {
    font-family: AvenirHeavy;
    font-size: 3.26vh;
    margin-top: 1.45vh;
    padding-bottom: 0.5vh;
}

.infoIndicadoresContainer {
    margin-top: 2.08vh;
}

.infoIndicadoresContainer_Title {
    padding-bottom: 2.08vh;
    font-size: 2.08vh;
    font-family: AvenirHeavy;
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.infoIndicadoresContainer_Row {
    display: grid;
    grid-template-columns: 3.125% 50% 23.4375% 23.4375%;
    padding: 2.08vh 0px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
}

.rowColor {
    height: 3.125vh;
    width: 100%;
}

.rowTitle {
    font-family: AvenirMedium;
    font-size: 1.3vh;
    color: #9a9ea6;
    padding-left: 0.78vw;
}

.rowValue {
    font-family: AvenirMedium;
    font-size: 1.56vh;
    font-weight: 500;
}

.rowPercentage {
    font-family: AvenirBook;
    font-size: 1.56vh;
    color: rgba(0, 0, 0, 0.6);
}

.iconoSucursal {
    height: 48px;
}

.simbologiaMainContainer {
    cursor: pointer;
    position: absolute;
    left: 20%;
    bottom: 5%;
}

.simbologiaButton {
    text-align: center;
    width: 8vh;
    height: 8vh;
    border-radius: 50%;
    background-color: black;
}

.simbologiaIcono {
    vertical-align: middle;
    height: 4vh;
}

.simbologiaInfoContainer {
    padding: 4%;
    width: 10vw;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 5px;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
}

.simbologiaTitle {
    font-family: AvenirHeavy;
    margin-bottom: 10px;
}

.simbologiaOption {
    font-family: AvenirMedium;
    margin: 6px 0px;
}

.simbologiaIcon {
    display: inline-block;
    width: 1vw;
    height: 1vh;
    border-radius: 3px;
}

.simbologiaText {
    display: inline-block;
    margin-left: 10px;
}

.loadingContainerSP {
    height: 100vh;
    text-align: center;
}

.loadingContainer {
    height: 100%;
    text-align: center;
}

.loading {
    vertical-align: middle;
}
