.filterContainer {
    height: 100%;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
}

.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.filterTitleContainer {
    display: flex;
    flex-direction: row;
    padding: 4%;
    border-bottom: 1px solid lightgray;
}

.filterTitle {
    padding-top: 5px;
    font-size: 14px;
    font-family: DDINExpBold;
}

.reiniciarButton {
    cursor: pointer;
    color: white;
    font-size: 12px;
    font-family: DDINExpBold;
    background-color: black;
    padding: 5px;
    border-radius: 5px;
    margin: 0 0 0 auto;
}

.sectionContainer {
    padding: 4%;
    border-bottom: 1px solid lightgray;
}

.filterSectionTitle {
    font-size: 12px;
    font-family: DDINExpBold;
}

.fechaContainer {
    padding: 2% 0%;
    border-bottom: 1px solid black;
}

.dateRadioTitle {
    margin: 2% 0px;
    font-family: DDINExp;
    font-size: 12px;
}

.radioContainer {
    padding: 2% 0%;
    border-bottom: 1px solid black;
}

.radioLabel {
    font-size: 12px;
    display: inline-block;
    margin-left: 0.2vw;
    font-family: DDINExp;
    vertical-align: middle;
}

.buttonMainContainer {
    position: relative;
}

.buttonContainer {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 8% 0%;
    border-bottom: 1px solid black;
}

/* .buttonContainer:hover {
    background-color: lightgray;
} */

.buttonArrow {
    margin: 0 0 0 auto;
}

.buttonInfoContainer {
    cursor: default;
    position: absolute;
    top: 0px;
    right: -525px;
    z-index: 99;
    width: 500px;
    background-color: white;
    // ==========================
    padding: 10px;
    // ==========================
    -webkit-box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
}

.buttonInfoTitleContainer {
    display: grid;
    grid-template-columns: 30% 70%;
}

.buttonInfoTitle {
    font-size: 24px;
    font-family: DDINExpBold;
}

.buttonInfoTitleExtra {
    margin-bottom: 25px;
}

.buttonInfoTitleExtraA {
    display: inline-block;
    font-size: 24px;
    font-family: DDINExpBold;
}

.buttonInfoTitleExtraB {
    display: inline-block;
    font-family: DDINExp;
    font-size: 12px;
    margin-left: 5px;
}

.checkListContainer {
    display: grid;
    grid-template-columns: 33% 33% 34%;
}

.capasContainer {
    border-bottom: 1px solid black;
}

.capasTitle {
    font-size: 13px;
    padding-top: 4%;
    font-family: DDINExpBold;
}

.capasOptionTitle {
    font-size: 12px;
    display: inline-block;
    padding: 4% 0px;
    font-family: DDINExp;
}

.switchContainer {
    float: right;
    padding-top: 1%;
}

// Estilos Radio Buttons ===========================================================================

.radioButtonContainer {
    cursor: pointer;
    display: block;
    position: relative;
    margin: 1% 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Custom Radio Button */
/* Hide the browser's default radio button */
.radioButtonContainer input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.radioCheckmark {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 1.5vh;
    width: 1.5vh;
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioButtonContainer:hover input ~ .radioCheckmark {
    background-color: rgba(0, 0, 0, 0.1);
}

/* When the radio button is checked, add a blue background */
.radioButtonContainer input:checked ~ .radioCheckmark {
    background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioButtonContainer input:checked ~ .radioCheckmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioButtonContainer .radioCheckmark:after {
    top: 25%;
    left: 25%;
    width: 0.75vh;
    height: 0.75vh;
    border-radius: 50%;
    background: white;
}

// Estilos Checkmarks ===========================================================================

.checkmarkContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;

    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkmarkContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    font-size: 12px;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    border: solid 1px #000;

    left: 0;
    font-size: 12px;

    height: 12px;
    width: 12px;
    background-color: rgb(255, 255, 255);
}

/* On mouse-over, add a grey background color */
.checkmarkContainer:hover input ~ .checkmark {
    background-color: lightgray;
}

/* When the checkbox is checked, add a blue background */
.checkmarkContainer input:checked ~ .checkmark {
    background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";

    background-image: "url(./icon/check@2x.png) no-repeat";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkmarkContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkmarkContainer .checkmark:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 6px;
    border: solid white;
    font-size: 12px;

    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
