.mainContainer {
    display: grid;
    grid-template-rows: 8.33% 91.67%;
    // =======================
    width: 100vw;
    height: 100vh;
}

.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.subContainer {
    display: grid;
    grid-template-columns: 15.625% 84.375%;
    height: 100%;
}

.infoContainer {
    position: relative;
    overflow: scroll;
}

.infoTitle {
    font-family: AvenirNextDemiBold;
    font-size: 4.17vh;
    padding: 0.5% 1%;
}

.infoSubtitle {
    font-size: 2vh;
    margin-left: 10px;
}

.ytdContainer {
    margin-top: -5px;
    font-family: AvenirNextDemiBold;
    font-size: 1.67vh;
    color: #939598;
}

.chartContainerA {
    display: grid;
    grid-template-columns: 49% 49%;
}

.chartTitle {
    font-family: AvenirHeavy;
    color: gray;
}

.chartContainerB {
    position: relative;
}

.switchContainer {
    position: absolute;
    height: 20px;
    right: 30px;
    top: 30px;
    z-index: 99;
}

.switchLabels {
    display: inline-block;
    vertical-align: middle;
    font-family: AvenirHeavy;
    font-size: 10px;
    padding: 10px;
}

.loadingContainer {
    height: 100vh;
    text-align: center;
}

.loading {
    vertical-align: middle;
}

.noDataContainer {
    position: absolute;
    width: 100%;
    top: 25%;
    text-align: center;
    font-family: AvenirHeavy;
    font-size: 4vh;
    z-index: 10;
}

.alcanceContainer {
    position: absolute;
    top: 2vh;
    right: 2vw;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.sucursalesInfoContainer {
    margin: 15px 0px;
}

.sucursalesGraphTitle {
    padding-left: 2%;
    font-size: 25px;
    font-family: AvenirNextDemiBold;
    color: "#040415";
}
