:root {
    --headerColor: #270f2b;
}
$headerColor: var(--headerColor);

.headerContainer {
    height: 100%;
    background-color: $headerColor;
}

.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.headerLogo {
    height: 50%;
    margin-left: 2%;
    vertical-align: middle;
}

.salirContainer {
    cursor: pointer;
    float: right;
    text-align: center;
    width: 5%;
    height: 100%;
    border-left: 1px solid white;
}

.iconoSalir {
    height: 40%;
    vertical-align: middle;
}

.menuOption {
    cursor: pointer;
    float: right;
    text-align: center;
    height: 100%;
    padding: 0% 1%;
    color: white;
}

.menuName {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.82vh;
    font-weight: normal;
}

.menuCircle {
    display: block;
    margin: 8px auto 0px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}
