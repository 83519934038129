.aligHelper {
    display: inline-block;
    height: 1%;
    vertical-align: middle;
}

.alcanceIcon {
    cursor: pointer;
    height: 2vh;
    vertical-align: middle;
    margin-left: 7px;
}

.alcanceSubtext {
    display: inline-block;
    font-size: 1.25vh;
    font-family: AvenirNextDemiBold;
    vertical-align: middle;
}

.cuadrito {
    width: 200%;
    height: 1.85vh;
}

.alcanceSubtext2 {
    display: flex;
    max-width: 100%;
    margin-top: 10px;
    font-size: 1.2vh;
    font-family: AvenirRoman;
}

.alcanceTooltipTitle {
    font-family: AvenirBlack;
    margin-bottom: 5px;
}

.alcanceTooltipBold {
    font-family: AvenirHeavy;
    font-weight: 900;
}

.alcanceTooltipBold2 {
    width: 100px;
    text-align: start;
    height: 10px;
    font-family: AvenirHeavy;
    font-weight: 900;
}

.alcanceTooltipBold3 {
    margin-right: 30px;
}

.alcanceSubcontainer {
    position: relative;
    display: inline-block;
}

/* Tooltip container */
.tooltipContainer {
    z-index: 99999;
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltipContainer .alcanceTooltip {
    width: 30vw;
    visibility: hidden;
    background-color: white;
    color: #555;
    font-size: 1.2vh;
    font-family: AvenirBook;
    text-align: left;
    padding: 10px 20px;
    border-radius: 6px;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.1);

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipContainer .alcanceTooltip2 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0px;
    grid-auto-rows: minmax(35px, auto);
    width: auto;
    visibility: hidden;
    background-color: white;
    color: #555;
    font-size: 1.2vh;
    font-family: AvenirBook;
    text-align: left;
    padding: 10px 20px;
    border-radius: 6px;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.1);

    /* Position the tooltip text */
    position: absolute;
    z-index: 9999;
    top: -5px;
    right: 105%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
/* .alcanceSubcontainer .alcanceTooltip::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
} */

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipContainer:hover .alcanceTooltip {
    visibility: visible;
    opacity: 1;
}

.groupName {
    width: 200px;
    justify-content: flex-start;
    display: flex;
}

/* .alcanceTooltipRow{
} */