.containerIndicador {
    position: absolute;
    top: 2vh;
    left: 50%;
    font-family: AvenirHeavy;
}

.volumenContainer {
    display: inline-block;
    padding-right: 1vw;
}

.valorContainer {
    display: inline-block;
    border-left: 2px solid #d1d3d4;
    padding-left: 1vw;;
}

.sectionTitle {
    font-size: 1.25vh;
    color: #939598;
}

.sectionValue {
    font-size: 2.71vh;
    color: #414042;
    margin-top: 5px;
}
