:root {
    --topContainerColor: #270f2b;
    --arrowFilter: brightness(0.05) saturate(100%) invert(6%) sepia(30%) saturate(3466%) hue-rotate(266deg) brightness(97%) contrast(97%);
    --bottomContainerColor: white;
    --inputBorder: 2px solid #371B3B;
    --inputColor: #4a314e;
    --inputTextColor: white;
    --buttonColor: #270f2b;
}
$topContainerColor: var(--topContainerColor);
$arrowFilter: var(--arrowFilter);
$bottomContainerColor: var(--bottomContainerColor);
$inputBorder: var(--inputBorder);
$inputColor: var(--inputColor);
$inputTextColor: var(--inputTextColor);
$buttonColor: var(--buttonColor);

.mainContainer {
    display: grid;
    grid-template-rows: 50% 50%;
    // =======================
    width: 100vw;
    height: 100vh;
    text-align: center;
}

.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.topContainer {
    position: relative;
    text-align: center;
    background-color: $topContainerColor;
    border-bottom: 6px solid #f1582b;
}

.mainLogo {
    /* height: 50%; */
    width: 50%;
    vertical-align: middle;
}

.arrowCircle {
    position: absolute;
    bottom: calc(0px - 1.25vw);
    left: calc(50vw - 1.25vw);
    width: 3vw;
    height: 3vw;
    filter: $arrowFilter;
}

.bottomContainer {
    background-color: $bottomContainerColor;
}

.formContainer {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    width: 20vw;
}

.loginInput {
    width: 100%;
    font-family: AvenirMedium;
    border: $inputBorder;
    border-radius: 5px;
    padding: 5px 5px 5px 12%;
    color: $inputTextColor;
    background: no-repeat scroll 10px center/4%;
    background-color: $inputColor;
}

.tokenContainer {
    text-align: left;
}

.tokenTitle {
    font-family: AvenirMedium;
    // color: $topContainerColor;
    color: #f1582b;
    margin: 30px 0px 10px;
}

.tokenInput {
    text-align: center;
    border: $inputBorder;
    border-radius: 12px;
    background-color: $inputColor;
    color: $inputTextColor;
    padding: 10px;
    margin-right: 10px;
}

.buttonLogin {
    cursor: pointer;
    font-family: AvenirHeavy;
    background-color: $buttonColor;
    border-radius: 10px;
    color: white;
    border: none;
    font-weight: bold;
    margin-top: 30px;
    padding: 10px 30px;
}

.errorMessage {
    font-family: AvenirHeavy;
    margin-top: 3%;
    color: #938977;
}
