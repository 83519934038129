.breadcrumb {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    margin-top: -0.2%;
    // border-radius: 5px;
    counter-reset: flag;
}
.breadcrumb a {
    cursor: default;
}
.breadcrumb__step {
    text-decoration: none;
    outline: none;
    display: block;
    float: left;
    font-size: 9px;
    line-height: 12px;
    // padding: 0 10px 0 10px;
    position: relative;
    background: transparent;
    color: white;
}
/* .breadcrumb__step:first-child {
    padding-left: 6px;
    border-radius: 5px 0 0 5px;
}
.breadcrumb__step:first-child::before {
    left: 4px;
}
.breadcrumb__step:last-child {
    border-radius: 0 5px 5px 0;
    padding-right: 0px;
}
.breadcrumb__step:last-child::after {
    content: none;
} */
/* .breadcrumb__step::before {
    counter-increment: flag;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 8px 0;
    position: absolute;
    top: 0;
    left: 30px;
    font-weight: bold;
    background: var(--breadcrumb-theme-2);
    box-shadow: 0 0 0 1px var(--breadcrumb-theme-1);
  } */
/* .breadcrumb__step::after {
    content: '';
    position: absolute;
    top: 0;
    right: -18px;
    width: 16px;
    height: 36px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    border-radius: 0 5px 0 50px;
    background: var(--breadcrumb-theme-2);
    transition: background 0.5s;
    box-shadow: 2px -2px 0 2px var(--breadcrumb-theme-4);
  } */
/* .breadcrumb__step--active {
    color: var(--breadcrumb-theme-2);
    background: transparent;
} */
/* .breadcrumb__step--active::before {
    color: var(--breadcrumb-theme-1);
  }
  .breadcrumb__step--active::after {
    background: var(--breadcrumb-theme-1);
  }
  .breadcrumb__step:hover {
    color: var(--breadcrumb-theme-2);
    background: var(--breadcrumb-theme-3);
  }
  .breadcrumb__step:hover::before {
    color: var(--breadcrumb-theme-1);
  }
  .breadcrumb__step:hover::after {
    color: var(--breadcrumb-theme-1);
    background: var(--breadcrumb-theme-3);
  } */

.breadcrumb_simbolo {
    width: 1.2vw;
    height: 3px;
    background-color: #58595b;
}
