.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.alcanceIcon {
    cursor: pointer;
    height: 2vh;
    vertical-align: middle;
    margin-left: 7px;
}

.alcanceSubtext {
    display: inline-block;
    font-size: 1.25vh;
    font-family: AvenirNextDemiBold;
    vertical-align: middle;
}

.alcanceTooltipTitle {
    font-family: AvenirBlack;
    margin-bottom: 5px;
}

.alcanceTooltipBold {
    font-family: AvenirHeavy;
}

.alcanceSubcontainer {
    position: relative;
    display: inline-block;
    margin: 0px 0px 2.2vh;
}

/* Tooltip container */
.tooltipContainer {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltipContainer .alcanceTooltip {
    display: inline-block;
    width: 14vw;
    visibility: hidden;
    background-color: white;
    color: #555;
    font-size: 1.2vh;
    font-family: AvenirBook;
    text-align: left;
    padding: 10px 10px;
    border-radius: 6px;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.1);

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
/* .alcanceSubcontainer .alcanceTooltip::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
} */

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipContainer:hover .alcanceTooltip {
    visibility: visible;
    opacity: 1;
}
