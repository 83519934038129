.aligHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.componentContainer {
    padding: 10px 2%;
}

.legendContainer {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.legendElement {
    display: flex;
    margin: 5px 0px;
}

.elementColor {
    width: 15px;
    height: 15px;
}

.elementTitle {
    font-family: AvenirHeavy;
    color: #58595b;
    font-size: 10px;
    padding: 2px 5px 0px;
}

.elementImage {
    cursor: pointer;
    height: 1.6vh;
}

/* Tooltip container */
.tooltipContainer {
    position: relative;
}

/* Tooltip text */
.tooltipContainer .elementTooltip {
    display: inline-block;
    width: 40vw;
    visibility: hidden;
    background-color: white;
    text-align: left;
    padding: 10px 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    overflow-x: scroll;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: -5px;
    // left: 105%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipContainer:hover .elementTooltip {
    visibility: visible;
    opacity: 1;
}

.tooltipSubcontainer {
    max-height: 50vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.subElementContainer {
    display: grid;
    grid-template-columns: 15px calc(calc(100% - 15px) * 0.75) calc(calc(100% - 15px) * 0.25);
    width: 20vw;
    margin: 3px 0px;
}

.subElementColor {
    width: 15px;
    height: 15px;
}

.subElementTitle {
    font-family: AvenirHeavy;
    color: #58595b;
    font-size: 1.04vh;
    padding-left: 5px;
}

.subElementTotal {
    text-align: center;
    font-family: AvenirMedium;
    color: #939598;
    font-size: 1.04vh;
}
