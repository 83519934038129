@font-face {
    font-family: DDINExp;
    src: url(./fonts/DDINExp/D-DINExp.ttf);
}

@font-face {
    font-family: DDINExpBold;
    src: url(./fonts/DDINExp/D-DINExp-Bold.ttf);
}

/* ====================================================== */

@font-face {
    font-family: SFMedium;
    src: url(./fonts/SFPro/SF-Pro-Display-Medium.otf);
}

@font-face {
    font-family: SFHeavy;
    src: url(./fonts/SFPro/SF-Pro-Display-Heavy.otf);
}

/* ====================================================== */

@font-face {
    font-family: AvenirHeavy;
    src: url(./fonts/Avenir/AvenirHeavy.otf);
}

@font-face {
    font-family: AvenirMedium;
    src: url(./fonts/Avenir/AvenirMedium.otf);
}

@font-face {
    font-family: AvenirBlack;
    src: url(./fonts/Avenir/AvenirLTStd-Black.otf);
}

@font-face {
    font-family: AvenirBook;
    src: url(./fonts/Avenir/AvenirLTStd-Book.otf);
}

@font-face {
    font-family: AvenirRoman;
    src: url(./fonts/Avenir/AvenirLTStd-Roman.otf);
}

/* ====================================================== */

@font-face {
    font-family: AvenirNextMedium;
    src: url(./fonts/AvenirNext/AvenirNext-Medium-06.ttf);
}

@font-face {
    font-family: AvenirNextDemiBold;
    src: url(./fonts/AvenirNext/AvenirNext-DemiBold-03.ttf);
}

@font-face {
    font-family: AvenirNextRegular;
    src: url(./fonts/AvenirNext/AvenirNext-Regular-08.ttf);
}

/* ====================================================== */
