.react-switch-bg {
    border: 1px solid rgba(54, 58, 67, 0.2) !important;
    border-radius: 3px !important;
    background-color: white !important;
    width: 3.125vw !important;
    height: 2.08vh !important;
}

.react-switch-handle {
    top: 0.46vh !important;
    border-radius: 20% !important;
    background-color: rgba(54, 58, 67, 0.2) !important;
    width: 1.17vw !important;
    height: 1.3vh !important;
}
